<template>
  <div>
    <el-form ref="formRef"
             label-width="100px">
      <el-row :gutter="10"
              style="padding-bottom:10px">
        <el-col :span="6">
          <el-form-item label="起始时间">
            <el-date-picker v-model="form.begin_time"
                            type="datetime"
                            style="width:100%"
                            value-format="yyyy-MM-dd HH:mm:ss"
                            placeholder="选择起始时间">
            </el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="截止时间">
            <el-date-picker v-model="form.end_time"
                            type="datetime"
                            style="width:100%"
                            default-time="23:59:59"
                            value-format="yyyy-MM-dd HH:mm:ss"
                            placeholder="选择截止时间" />
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-button type="primary"
                     @click="search">提交</el-button>
        </el-col>
      </el-row>
    </el-form>
    <Table ref="TablesRef"
           :tableData="tableData" />
    <pagination ref="pagination"
                :get-data="initData"
                :now-page.sync="page"
                :now-size.sync="size"
                :total="totalElements" />
  </div>
</template>

<script>
export default {
  data () {
    return {
      tableData: [],
      form: {
        begin_time: '',
        end_time: '',
      },
      page: 1,
      size: 10,
      totalElements: 0,
      pageName: 'lutiAdmin_admin',
      // 编号,科目,试卷地区,试卷年份,试卷来源ID,试卷来源名,试卷编码,录入题目数,录题完成时间,校对完成时间
      tableColumns: [
        { prop: 'user_id', align: 'center', label: '用户ID', width: '', showToopic: false },
        { prop: 'subject_name', align: 'center', label: '科目', width: '130', showToopic: false },
        { prop: 'district_name', align: 'center', label: '试卷地区', width: '120', showToopic: false },
        { prop: 'question_year', align: 'center', label: '试卷年份', width: '', showToopic: false },
        { prop: 'source_id', align: 'center', label: '试卷来源ID', width: '', showToopic: false },
        { prop: 'source_name', align: 'center', label: '试卷来源名', width: '', showToopic: false },
        { prop: 'paper_code', align: 'center', label: '试卷编码', width: '', showToopic: false },
        { prop: 'user_input_count', align: 'center', label: '录入题目数', width: '', showToopic: false },
        { prop: 'max_input_time', align: 'center', label: '录题完成时间', width: '', showToopic: false },
        { prop: 'check_time', align: 'center', label: '校对完成时间', width: '', showToopic: false },
        {
          prop: 'd', align: 'center', label: '操作', width: '', type: 'img', btns: [
            { content: '试卷详情', type: 'look', event: 'showForm' },
          ]
        },
      ]
    }
  },
  mounted () {
    this.setTablesColums()
  },
  methods: {
    setTablesColums () {
      this.$refs.TablesRef.pageName = this.pageName
      this.$refs.TablesRef.setColumns(this.tableColumns)
    },
    initData (page, limit) {
      this.form = JSON.parse(this.$route.query.form)
      let form = JSON.parse(JSON.stringify(this.form))
      form.page = page
      form.limit = limit
      this.$http({
        url: '/api/v2/stats/record_detail',
        method: 'get',
        params: form
      }).then(res => {
        this.tableData = res.data.list
        this.totalElements = res.data.count
      })
    },
    showForm (row) {
      this.$router.push(`/topicAdmin/usertopicList/info?question_year=${row.question_year}&source_id=${row.source_id}`)
    },
    search () {
      this.$refs.pagination.toFirstPage()
    }
  }
}
</script>

<style lang="scss" scoped>
</style>